// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgChatBubbleOutline = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.664 1.664H3.331c-.917 0-1.667.75-1.667 1.667v15l3.333-3.334h11.667c.917 0 1.667-.75 1.667-1.666v-10c0-.917-.75-1.667-1.667-1.667m-.833 11.667H4.997l-1.666 1.666V4.164c0-.458.375-.833.833-.833h11.667c.458 0 .833.375.833.833v8.333a.836.836 0 0 1-.833.834"
    />
  </svg>
);
export const ChatBubbleOutlineIcon = forwardRef(SvgChatBubbleOutline);
